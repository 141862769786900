<template>
  <DatasetsCard />
</template>

<script>
import DatasetsCard from '@/components/DatasetsCard';

export default {
  components: {
    DatasetsCard
  }
};
</script>
